<template>
  <v-btn
    :color="color"
    :depressed="depressed"
    :min-width="minWidth"
    :outlined="!filled"
    class="font-weight-bold"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />

    <v-icon right v-if="icon" style="margin-left: 10%">
      {{ icon }}
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'BasicBtn',

  props: {
    color: {
      type: String,
      default: 'primary'
    },
    depressed: {
      type: Boolean,
      default: true
    },
    minWidth: {
      type: [Number, String],
      default: 164
    },
    filled: {
      type: Boolean,
      default: true
    },
    icon: {
      type: String,
      default: null
    }
  }
}
</script>

<style scoped>
.v-btn {
  border: 2px solid;
  background-color: white;
}
</style>
